import React from 'react';
import PropTypes from 'prop-types';
import Club404 from '../components/errors/club-404';
import CLUB_PROP_TYPES from '../lib/prop-types/club';
import GenericError from '../components/errors/generic';

const captureException = async (error, ctx) => {
  const sentry = await import('../lib/sentry');
  const { captureException: sentryCapture } = sentry.default();
  return sentryCapture(error, ctx);
};

const doNotReport = ['ClubWebsitePageNotFoundException'];

class ErrorPage extends React.Component {
  static async getInitialProps({ res, err, ...otherProps }) {
    let statusCode;
    if (res && res.statusCode) {
      ({ statusCode } = res);
    } else {
      statusCode = err ? err.statusCode : 404;
    }

    let eventId;
    if (err && !doNotReport.includes(err.name)) {
      eventId = await captureException(err, otherProps);
    }

    return {
      err,
      statusCode,
      eventId,
      namespacesRequired: ['common'],
    };
  }

  render() {
    const { statusCode, club, eventId } = this.props;

    if (statusCode === 404 && club) {
      return <Club404 club={club} />;
    }

    return <GenericError eventId={eventId} />;
  }
}

ErrorPage.defaultProps = {
  club: null,
  eventId: null,
  statusCode: null,
};

ErrorPage.propTypes = {
  club: CLUB_PROP_TYPES,
  eventId: PropTypes.number,
  statusCode: PropTypes.number,
};

export default ErrorPage;
